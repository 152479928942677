
import HelpMenu from '@/components/HelpMenu.vue';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  data() {
    return {}
  },
  components: {
    HelpMenu,
  }
});
