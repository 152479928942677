<template>
  <div id="siteContainer">
    <div id="mainHeading">
      <div class="header">
        <h1>
          <router-link to="/help">Help</router-link>
        </h1>
        <breadcrumbs></breadcrumbs>
      </div>
    </div>

    <router-view id="mainColumn1"></router-view>

    <div id="mainColumn2">
      <div id="leftnavContent">
        <help-menu></help-menu>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script lang="ts">
import HelpMenu from '@/components/HelpMenu.vue';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  data() {
    return {}
  },
  components: {
    HelpMenu,
  }
});
</script>
