
  import SecondaryNavigation from '@/components/SecondaryNavigation.vue';

  export default {
    data: function() {
      return {
        menuEntries: [
          {
            title: "Help Center",
            image: require("@/assets/help-menu/help.png"),
            links: [
              {
                title: "Help Center Home",
                href: "/help/center",
              },
              {
                title: "Game Questions (FAQs)",
                href: "/help/center/knowledge",
              },
              {
                title: "Request Help",
                href: "/help/center/ticket",
              },
              {
                title: "Agreements",
                href: "/help/agreements",
              },
            ]
          },
          {
            title: "User Guide",
            image: require("@/assets/help-menu/user-guide.png"),
            href: "/help/userguide"
          },
          {
            title: "Tutorials",
            image: require("@/assets/help-menu/tutorials.png"),
            links: [
              {
                title: "Brick Mode",
                href: "/help/content/221623"
              },
              {
                title: "Behaviors",
                href: "/help/content/221622"
              },
              {
                title: "Racing",
                href: "/help/content/221611"
              }
            ]
          },
          {
            title: "Tech Topics",
            image: require("@/assets/help-menu/tech-topics.png"),
            href: "/help/tech-topics"
          },
          {
            title: "Agreements",
            image: require("@/assets/help-menu/help.png"),
            href: "/help/agreements"
          }
        ]
      }
    },
    components: {
      SecondaryNavigation,
    }
  }
